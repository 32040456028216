module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"adenin","short_name":"adenin","start_url":"/","id":"/","description":"Finally, a better way to keep track of your work! adenin puts all of your essential work apps data into a personal dashboard. Work smarter and interact, create, or embed your data any which way you want. Install the PWA to your device now and get started for free!","categories":["business","personalization","productivity"],"orientation":"portrait","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","display_override":["standalone","minimal-ui","window-controls-overlay"],"icon":"src/images/icon-512x512.png","launch_handler":{"client_mode":["focus-existing, auto"]},"protocol_handlers":[{"protocol":"at+card","url":"/app/assistant/card/%s"}],"shortcuts":[{"name":"Dashboard","url":"/app/assistant/grid-board","description":"See all my important information on one page"}],"screenshots":[{"src":"https://www.adenin.com/assets/images/PWA-Screenshot-1-2023.webp","sizes":"1280x720","type":"image/webp"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f70b1f54dd239e4795cbd4b3822654c8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline-next/gatsby-browser.js'),
      options: {"plugins":[],"swSrc":"/srv/adenin/actions-runner/_work/platform-client/platform-client/src/sw.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
