/* eslint-disable import/no-cycle */

import { apiUrl, defaultFetchOpts } from '../config';
import { isSSR } from './helpers';

export const TRACKING_EVENTS = {
  AssistantStartMobile: 'Assistant_Start_Mobile',
  AssistantStartDesktop: 'Assistant_Start_Desktop',
  AssistantStartMobilePWA: 'Assistant_Start_Mobile_PWA',
  AssistantStartDesktopPWA: 'Assistant_Start_Desktop_PWA',
  AssistantChatStart: 'Assistant_Chat_Start',
  CardsManage: 'Cards_Manage',
  AdminGetStarted: 'Admin_Get_Started',
  SettingsSidebarOpened: 'Settings_Sidebar_Opened',
  TenantDelete: 'Tenant_Delete',
  AdminStarted: 'Admin_Started',
  AppPickerClick: 'App_Picker_Click',
  NotificationsOpened: 'Notifications_Opened',
};

function constructOpts(body) {
  const opts = {
    ...defaultFetchOpts,
    method: 'POST',
    body: JSON.stringify(body),
  };

  opts.headers.Accept = 'application/json';
  opts.headers['Content-Type'] = 'application/json';

  const accessToken = sessionStorage?.getItem('pkce_token');

  if (accessToken) {
    const token = JSON.parse(accessToken)?.access_token;
    opts.headers.Authorization = `Bearer ${token}`;
  } else {
    opts.credentials = 'include';
  }

  return opts;
}

export const track = (eventName, detailOrTitle, detailOrEmpty = {}) => {
  if (isSSR || !eventName) {
    return;
  }

  let title = '';
  let detail = detailOrEmpty;

  if (typeof detailOrTitle === 'string') {
    title = detailOrTitle;
  } else if (typeof detailOrTitle === 'object') {
    detail = detailOrTitle;
  }

  const opts = constructOpts({
    feature: eventName,
    detail,
    title,
  });

  fetch(`${apiUrl}/featurelog/log`, opts);
};

const reportError = (args, level) => {
  if (isSSR || !args.length) {
    return;
  }

  const payload = {
    host: window.location.hostname,
    level,
  };

  for (let i = 0; i < args.length; i++) {
    if (typeof args[i] === 'string' && !payload.message) {
      payload.message = args[i];
      continue;
    }

    if (args[i] instanceof Error) {
      payload.message = args[i].message;
      payload.stack = args[i].stack;
      break;
    }
  }

  const opts = constructOpts(payload);
  fetch(`${apiUrl}/error/submit`, opts);
};

export const ErrorReporter = {
  info: (...args) => {
    reportError(args, 'INFO');
  },
  error: (...args) => {
    reportError(args, 'ERROR');
  },
  warn: (...args) => {
    reportError(args, 'WARN');
  },
  debug: (...args) => {
    reportError(args, 'DEBUG');
  },
};
